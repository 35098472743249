import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { MediaImage } from "../common/Media";
import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import { CallToAction, LargeCallToAction } from "../components/CallToAction";
import { TestimonialsCarousel } from "../components/Carousel";
import { PestsGrid, PestsGridItem } from "../components/Grid";
import { PageHero } from "../components/Hero";


const PestLibraryPage: React.FC = () => {
  const data: any = useStaticQuery(graphql`
    query {
      pestlibrarypage: mdx(
        frontmatter: {
          title: {
            eq: "Pest Library"
          }
        }
      ) {
        frontmatter {
          pageHeading
          pageDescription
          heroImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }

      pests: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "pest"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }

      testimonials: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "testimonial"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              source
              reviewer
              review
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title="Pest Library"
      />

      <SectionWrapper
        background="linear-gradient(#FBF7F1 0%, #FBF7F1 80%, white 80%, white 100%)"
        padding="0 0 45px"
      >
        <SectionContainer>
          <PageHero
            HeroComponent={
              <MediaImage
                image={ data.pestlibrarypage.frontmatter.heroImage.childImageSharp.gatsbyImageData }
                alt=""
                containerWidth={{
                  base: '100%',
                  lg: '668px',
                  xl: '840px',
                  '2xl': '1012px'
                }}
                containerHeight={{
                  base: 'auto',
                  lg: '398px',
                  xl: '500px',
                  '2xl': '602px'
                }}
                borderRadius="14px"
              />
            }
            heading={ data.pestlibrarypage.frontmatter.pageHeading }
            description={ data.pestlibrarypage.frontmatter.pageDescription }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="71px 0 76px"
      >
        <SectionContainer>
          <PestsGrid>
            { data.pests.edges.map(( pest: any, _index: number ) => {
              if (pest.node.frontmatter.heading === "Ants" || pest.node.frontmatter.heading === "Bed Bugs" || pest.node.frontmatter.heading === "Cockroaches") {
                return (
                  <PestsGridItem
                    heading={ pest.node.frontmatter.heading }
                    description={ pest.node.frontmatter.description }
                    image={ pest.node.frontmatter.image.childImageSharp.gatsbyImageData }
                  />
                )
              } else {
                return null;
              }
            })}
          </PestsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
      >
        <LargeCallToAction
          heading="Servicing San Diego County for Over 20 Years. Our service professionals have earned over 3,700+ reviews !"
        />
      </SectionWrapper>

      <SectionWrapper
        padding="106px 0 103px"
      >
        <SectionContainer>
          <PestsGrid>
            { data.pests.edges.map(( pest: any, _index: number ) => {
              if (pest.node.frontmatter.heading !== "Ants" && pest.node.frontmatter.heading !== "Bed Bugs" && pest.node.frontmatter.heading !== "Cockroaches" && pest.node.frontmatter.heading !== "Wildlife") {
                return (
                  <PestsGridItem
                    heading={ pest.node.frontmatter.heading }
                    description={ pest.node.frontmatter.description }
                    image={ pest.node.frontmatter.image.childImageSharp.gatsbyImageData }
                  />
                )
              } else {
                return null;
              }
            })}
          </PestsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Our service and professionals have earned over 3,700+ reviews!"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control has helped thousands of property owners over the past 20 years. Here are what some of our customers have to say."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
        </SectionContainer>
        <TestimonialsCarousel
          nodes={ data.testimonials.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading="Request a Free Quote"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default PestLibraryPage;
